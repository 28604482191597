html,
body {
  width: 100%;
  height: 100%;
  .font-14_h20_Re() !important;
  color: @text-base-color !important;
  background-color: @body-bg !important;
  min-width: @body-min-width !important;
  box-sizing: border-box;
  font-variant: tabular-nums;
  a {
    color: @link-base-color;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    cursor: pointer;
  }
  a:hover {
    color: @link-hover-color;
  }
  * {
    font-family: @font-family;
  }
}
.mixin-set-scrollbar(12px,@scrollbar-bg);

:global {
  #portal {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  *[id*='root-'] {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
  }
  .flex {
    display: flex;
    &-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-start {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-end {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .generate-spacing(@n, @i: 1) when (@i =< @n) {
    .mt-@{i} {
      margin-top: extract(@spacing, @i);
    }
    .mb-@{i} {
      margin-bottom: extract(@spacing, @i);
    }
    .ml-@{i} {
      margin-left: extract(@spacing, @i);
    }
    .mr-@{i} {
      margin-right: extract(@spacing, @i);
    }
    .pt-@{i} {
      padding-top: extract(@spacing, @i);
    }
    .pb-@{i} {
      padding-bottom: extract(@spacing, @i);
    }
    .pl-@{i} {
      padding-left: extract(@spacing, @i);
    }
    .pr-@{i} {
      padding-right: extract(@spacing, @i);
    }
    .generate-spacing(@n, (@i + 1));
  }
  .generate-spacing(length(@spacing)) !important;

  .clr-success {
    color: @success-base-color;
  }
  .clr-info {
    color: @primary-highTop-color;
  }
  .clr-warning {
    color: @warning-base-color;
  }
  .clr-error {
    color: @error-base-color;
  }
  .clr-stop {
    color: @text-low-color;
  }
  .clr-price {
    color: @text-price-color;
  }
  .clr-link {
    color: @link-base-color;
  }
  .clr-codeFont {
    font-family: @code-font-family;
  }

  @media (max-width: 873px) {
    .hidden-s {
      display: none !important;
    }
  }
  @media (max-width: 1200px) {
    .hidden-m {
      display: none !important;
    }
  }
  @media (max-width: 1500px) {
    .hidden-l {
      display: none !important;
    }
  }
  @media (max-width: 1500px) {
    .hidden-xl {
      display: none !important;
    }
  }
}

@hack: true; @import (reference) "~@zenlayer/zen-design/esm/style/index.less";